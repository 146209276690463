.withTooltip {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip {
  font-size: 12px;
  position: absolute;
  z-index: 30;
  display: flex;
  visibility: hidden;
  flex-wrap: nowrap;
  justify-content: center;
  min-width: 50px;
  width: max-content;
  padding: 5px 10px;
  -webkit-transition: opacity .5s ease-in-out;
     -moz-transition: opacity .5s ease-in-out;
          transition: opacity .5s ease-in-out;
  text-align: center;
  text-transform: capitalize;
  opacity: 0;
  border-radius: 4px;
  background: rgba(36, 43, 67, .9);
  color: var(--color-font-light);

  &.up {
    top: -45px;
  }

  &.up::after {
    top: 33px;
    transform: rotate(180deg);
  }

  &.down {
    top: 35px;
  }
  &.down::after {
    top: -10px;
    transform: rotate(0);
  }
  &.left {
    top: 0;
    right: 45px;
  }
  &.left::after  {
    top: 5px;
    left: 58px;
    transform: rotate(90deg);
  }
  &.center {
    left: auto;
  }
}

.tooltip::after {
  position: absolute;
  top: -10px;
  left: 45%;
  width: 0;
  height: 0;
  content: "";
}

.tooltipActive {
  visibility: visible;
  opacity: 1;
}
